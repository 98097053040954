const menu = () => {
  const { body } = document;
  const toggle = body.querySelector('.toggle');
  const aside = body.querySelector('.aside');
  const nav = body.querySelector('.nav');
  const links = body.querySelectorAll('.js-menu-item')
  const mediaQueryList = window.matchMedia('(max-width: 1024px)')

  const closeMenu = () => {
    toggle.classList.remove('active');
    aside.classList.remove('active');
    nav.classList.remove('active');
  }

  const openMenu = () => {
    toggle.classList.add('active');
    aside.classList.add('active');
    nav.classList.add('active');
  }

  toggle.addEventListener('click', () => {
    const isMobileNavOpened = aside.classList.contains('active');

    if(isMobileNavOpened) {
      closeMenu();
    } else {
      openMenu();
    }
  })

  mediaQueryList.addEventListener('change', () => {
    closeMenu()
  })

  links.forEach(el => {
    el.addEventListener('click', () => {
      closeMenu()
    })
  })
};

export default menu;
