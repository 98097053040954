import {Swiper} from 'swiper';

// eslint-disable-next-line import/extensions
import { Navigation, Pagination } from 'swiper/modules/index.mjs';

Swiper.use([ Navigation, Pagination]);

const slider = () => {
  return new Swiper('.swiper', {
    spaceBetween: 70,
    slidesPerView: 6,
    breakpoints: {
      320: {
        slidesPerView: 1
      },
      768: {
        slidesPerView: 2
      },
      1440: {
        slidesPerView: 3
      },
    },
    navigation: {
      nextEl: ".swiper-button-next",
      prevEl: ".swiper-button-prev",
      clickable: true,
    },
    pagination: {
      el: ".swiper-pagination",
      clickable: true,
    },
  });
}

export default slider
