import scroll from "./scroll";
import menu from "./menu"
import animation from "./animation";
import header from "./header";
import accordion from "./accordion";
import slider from "./slider";
import tab from "./tab";

window.addEventListener('DOMContentLoaded', () => {
  scroll()
  menu()
  animation()
  header()
  accordion()
  slider()
  tab()

  window.onscroll = () => {
    const top = window.scrollY;

    document.querySelectorAll('section[id*="section"]').forEach(section => {
      const offset = section.offsetTop;
      const height = section.offsetHeight;
      const id = section.getAttribute('id');
      const links = document.querySelectorAll(`a[href="#${id}"]`);

      if (top >= offset && top < offset + height) {
        links.forEach(link => {
          link.classList.add('active');
        });
      } else {
        links.forEach(link => {
          link.classList.remove('active');
        });
      }
    });
  };
})
