import { gsap } from 'gsap';

const header = () => {
  const tl = gsap.timeline()
  const block = document.querySelector('.header')
  const title = block.querySelector('.js-headline');
  const image = block.querySelector('.js-image');
  const text = block.querySelectorAll('.js-text');
  const button = block.querySelector('.js-button');
  const socials = block.querySelector('.js-social');

  tl
    .from(title,{opacity: 0, y: -24, duration: 0.3}, 'key+=0.4')
    .from(text[0],{opacity: 0, y: -24, duration: 0.3}, 'key+=0.4')
    .from(image,{opacity: 0, y: -24, duration: 0.3}, 'key+=0.6')
    .from(button, {opacity: 0, y: -24, duration: 0.3}, 'key+=0.6')
    .from(text[1],{opacity: 0, y: -24, duration: 0.3}, 'key+=0.8')
    .from(socials,{opacity: 0, x: 24, duration: 0.3}, 'key+=0.4')
}

export default header
