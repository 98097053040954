import { gsap } from 'gsap';
import { ScrollTrigger } from "gsap/ScrollTrigger";

gsap.registerPlugin(ScrollTrigger);

const animation = () => {
  const animateCounter = () => {
    const counter = document.querySelector(".js-number-animation");

    gsap.fromTo(
      counter,
      {
        innerHTML: counter.getAttribute('data-from')
      },
      {
        innerHTML: counter.getAttribute('data-to'),
        duration: counter.getAttribute('data-duration'),
        ease: "power1.out",
        onUpdate: () => {
          counter.innerHTML = `${Math.ceil(counter.innerHTML)}+`
        }
      }
    )
  }

  ScrollTrigger.batch('.js-on-scroll-animation', {
    start: "top 100%",
    toggleClass: "active",
    once: true,
  })

  ScrollTrigger.batch('.js-number-animation', {
    start: "top 100%",
    toggleClass: "active",
    once: true,
    onEnter: () => {
      animateCounter();
    }
  })
}

export default animation
