const accordion = () => {
  const items = document.querySelectorAll('.js-accordion-item')

  items.forEach((item) => {
    item.querySelector('.js-accordion-trigger').addEventListener('click', () => {
      items.forEach((el) => {
        if (el !== item) el.classList.remove('accordion__item--active');
      });

      item.classList.toggle('accordion__item--active')
    });
  });
}

export default accordion
