const tab = () => {
  const items = document.querySelectorAll('.js-steps-link')
  const contents = document.querySelectorAll('.js-steps-body')

  items.forEach((item) => {
    item.addEventListener('click', () => {
      items.forEach((el) => {
        el.classList.remove('steps__link--active');
      })

      contents.forEach((el) => {
        el.classList.remove('steps__body--active');
      })

      document.querySelectorAll(`[data-toggle="${item.getAttribute('data-toggle')}"]`).forEach((el) => {
        el.classList.add('steps__link--active');
      })

      document.querySelector(`[data-toggle="${item.getAttribute('data-toggle')}"]`).classList.add('steps__link--active')
      contents[item.getAttribute('data-toggle')].classList.toggle('steps__body--active')
    });
  });
}

export default tab
